<template>
  <v-container fluid>
    <PageHeader showBackButton :isLoading="$store.getters.isLoading"> </PageHeader>
    <v-form ref="dataForm" @submit.prevent="save">
      <v-card class="mb-8">
        <v-card-text>
          <v-card-title class="page-title">優惠券資料</v-card-title>
          <v-row class="ma-0">
            <v-col cols="12" md="6">
              <form-input
                label="優惠碼（如不填寫，系統會自動產生一個優惠碼）"
                :fieldValue.sync="formData.discount_code"
                :readonly="$validate.DataValid(initData.discount_code)"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-input label="優惠券名稱" :fieldValue.sync="formData.name" required />
            </v-col>

            <v-col cols="12" md="6" v-if="id !== null">
              <form-select label="狀態" :fieldValue.sync="formData.status" :options="$statusOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="可重用" :fieldValue.sync="formData.reusable" required :options="reusableOptions" />
            </v-col>

            <v-col cols="12" md="6">
              <form-select label="優惠類型" :fieldValue.sync="formData.discount_type" :options="typeOptions" required />
            </v-col>

            <v-col cols="12" md="6">
              <form-input
                :label="couponDisplay"
                :fieldValue.sync="formData.discount_value"
                required
                type="number"
                isIntegerOnly
                :customRules="[
                  v => v > 0 || '優惠必須大於0',
                  v => {
                    return formData.discount_type === 'percent' && this.$validate.regexNumber(v)
                      ? parseInt(v) <= 100 || '百分比不大於100'
                      : true
                  },
                ]"
                :suffix="formData.discount_type === 'percent' ? '% off' : ''"
                :prefix="formData.discount_type === 'amount' ? '- $' : ''"
                :disabled="!formData.discount_type"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-select
                label="指定帳戶"
                :fieldValue.sync="formData.user_id_list"
                :options="userOptions"
                :required="!formData.allow_all_user"
                isMultiple
                :readonly="formData.allow_all_user"
              />
              <v-checkbox class="mt-0" v-model="formData.allow_all_user" label="全部帳戶"></v-checkbox>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-btn depressed color="primary accent-4" class="white--text" type="submit" :loading="$store.getters.isLoading">
        儲存
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import FormInput from '@/components/form/FormInput.vue'
import FormSelect from '@/components/form/FormSelect.vue'

export default {
  name: 'CouponDetail',
  components: {
    FormInput,
    FormSelect,
  },
  computed: {
    id() {
      const id = parseInt(this.$route.params.id)
      if (!isNaN(id)) {
        return id
      }

      return null
    },
    couponDisplay() {
      let amount = '0'
      if (this.formData.discount_type === 'amount') {
        if (this.formData.discount_value) {
          amount = this.formData.discount_value
        }
        return `價格優惠：減價 $${amount}`
      } else if (this.formData.discount_type === 'percent') {
        if (this.formData.discount_value) {
          if (parseInt(this.formData.discount_value) >= 100) {
            amount = '10'
          } else {
            const d = 100 - parseFloat(this.formData.discount_value)
            if (d % 10 === 0) {
              amount = (d * 0.1).toFixed(0)
            } else {
              amount = (d * 0.1).toFixed(1)
            }
          }
        }
        return `百份比優惠：${amount}折`
      }

      return '優惠'
    },
  },
  data: () => ({
    formData: {
      discount_code: '',
      name: '',
      status: 'active',
      reusable: null,
      discount_type: null,
      discount_value: '',
      user_id_list: [],
      allow_all_user: true
    },
    initData: {
      discount_code: '',
    },

    typeOptions: [
      { value: 'percent', text: '百分比' },
      { value: 'amount', text: '固定金額' },
    ],
    reusableOptions: [
      { text: '否', value: false },
      { text: '是', value: true },
    ],
    userOptions: [],
  }),

  methods: {
    ...mapActions(['setAlert']),
    async getUserOptions() {
      try {
        const user = this.getCurrentUserData()
        const { data } = await this.$Fetcher.GetUsers({ filter_status: 'active' }, user.id, user.token)
        this.userOptions = data.map(el => {
          return { text: `${el.name} (${el.email})`, value: el.id }
        })
      } catch {}
    },

    async getDetail() {
      try {
        const user = this.getCurrentUserData()
        const data = await this.$Fetcher.GetPromoById(this.id, user.id, user.token)
        for (const key in this.formData) {
          if (this.$validate.DataValid(data[key])) {
            this.$set(this.formData, key, data[key])
          }
        }

        if (data.discount_code) {
          this.initData.discount_code = data.discount_code
        }

        if (data.discount_type === 'percent') {
          this.formData.discount_value = 100 - data.discount_value;
        }
      } catch (err) {
        this.$common.error(err)
        if (err) {
          this.setAlert({
            type: 'error',
            message: err,
            redirect: 'Promo',
          })
        } else {
          this.setAlert({
            message: 'noData',
            redirect: 'Promo',
          })
        }
      } finally {
        this.$store.dispatch('setInitLoading', false)
      }
    },

    async save() {
      if (this.$store.getters.isLoading) {
        this.$store.dispatch('toggleSnack', {
          message: 'processing',
        })
        return
      }

      const valid = await this.$refs['dataForm'].validate()
      if (!valid) {
        this.$store.dispatch('toggleSnack', {
          message: 'required',
        })
        return
      }

      this.$store.dispatch('setLoading', true)
      let payload = {
        status: this.formData.status,
        name: this.formData.name,
        discount_type: this.formData.discount_type,
        reusable: this.formData.reusable,
        allow_all_user: this.formData.allow_all_user,
      }

      if (this.formData.discount_code) {
        payload['discount_code'] = this.formData.discount_code;
      }

      if (this.formData.discount_type === 'percent') {
        payload['discount_value'] = 100 - parseInt(this.formData.discount_value);
      } else {
        payload['discount_value'] = parseFloat(this.formData.discount_value);
      }

      if (!this.formData.allow_all_user) {
        payload['user_id_list'] = this.formData.user_id_list;
      } else {
        payload['user_id_list'] = [];
      }

      const user = this.getCurrentUserData()

      if (this.$validate.DataValid(this.id)) {
        try {
          await this.$Fetcher.UpdatePromo(this.id, payload, user.id, user.token)
          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '儲存成功',
            refresh: true,
          })
        } catch (err) {
          this.setAlert({
            title: '儲存失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      } else {
        try {
          await this.$Fetcher.NewPromo(payload, user.id, user.token)

          this.$store.dispatch('setDataIsUpdated', false)
          this.$store.dispatch('toggleSnack', {
            type: 'success',
            message: '新增成功',
            redirect: 'Promo',
          })
        } catch (err) {
          this.setAlert({
            title: '新增失敗',
            message: err,
            type: 'error',
          })
        } finally {
          this.$store.dispatch('setLoading', false)
        }
      }
    },
  },
  async created() {
    this.$store.dispatch('setInitLoading', true)
    await this.getUserOptions();
    if (this.$validate.DataValid(this.id)) {
      this.getDetail()
    } else {
      this.$store.dispatch('setInitLoading', false)
    }
  },

  // ------ navigation guard ------
  beforeRouteLeave(to, from, next) {
    if (this.$store.getters.isLoading) {
      this.$store.dispatch('toggleSnack', {
        show: true,
        message: 'processing',
        type: 'error',
        refresh: false,
        redirect: '',
      })
      next(false)
    } else {
      next()
    }
  },
}
</script>
